<template>
  <section class="main-section closet-section">
    <div class="closet-1">
      <div class="closet-1-img font-0"><img :src="ossPath + 'closet-1.png'" class="full-img" /></div>
      <div class="closet-label font-0"><img :src="ossPath + 'cabinet-label-1.png'" class="full-img" /></div>
    </div>
    <div class="closet-content closet-1-content">
      <div class="closet-title">SOLA 灵动系列</div>
      <div class="closet-desc">
        <p>采⽤<b>优雅精致的构造理念，</b>从地板到天花板的 ⾼度都是定制的，允许配件在数量和⾼度上⾃由定位，该系统能轻易并顺畅地与房屋内饰进⾏搭配。</p>
      </div>
    </div>
    <div class="cabinet-bg-bottom closet-1-bg-bottom"><div></div></div>
    <div class="closet-2">
      <div class="closet-2-img font-0"><img :src="ossPath + 'closet-2.png'" class="full-img" /></div>
      <div class="closet-content">
        <div class="closet-title closet-title-right">AMBO 雅居系列</div>
        <div class="closet-desc">
          <p>AMBO 是⼀个由多种模块组成的壁挂系统。墙板固定在墙上，并与抽屉，铝框玻璃层板，裤抽，丝⼱架，镜⼦等其他功能模块组合设计。<b>采⽤⽆线取电系统，可灵活的定位照明组件的⾼度</b>。</p>
        </div>
      </div>
    </div>
    <div class="cabinet-bg-bottom cabinet-bg-bottom-left"><div></div></div>
    <div class="closet-3">
      <div class="closet-3-img font-0"><img :src="ossPath + 'closet-3.png'" class="full-img" /></div>
      <div class="closet-3-label font-0"><img :src="ossPath + 'cabinet-label-2.png'" class="full-img" /></div>
    </div>
    <div class="closet-content closet-3-content">
      <div class="closet-title">OMNIA 豪迈系列</div>
      <div class="closet-desc">
        <p>OMNIA 包含多种开门⽅式的⾐帽系统，如平开门、 移门、折叠门以及步⼊式，配合多种可选的门 板材质，如铝框玻璃、实⽊贴⽪、肤感⾯板等， 可根据空间设计不同的开门⽅式，<b>使得空间利⽤更加合理，让⽣活变得更加⽅便舒适</b>。</p>
      </div>
    </div>
    <div class="cabinet-bg-bottom"><div></div></div>
  </section>
</template>
<script>
export default {
  data: () => ({
    ossPath: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/'
  }),
  created () {
    this.$store.commit('updateActiveNav', 1)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/space.css';
</style>
